<template>
    <div>
        <v-card class="ma-sm-1 mb-sm-6 mb-2 flatcard" color="grey lighten-5">
            <v-card-title>
                Persönliche Informationen
            </v-card-title>
            <v-card-text>
                <p class="d-inline d-sm-none">
                    Angaben zur Familiensituation
                </p>
                <v-form ref="form" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col sm="8" cols="12">
                                <v-select
                                    label="Wie ist Ihr Familienstand?"
                                    v-model="data.familyStatus"
                                    :items="familyStatusTypes"
                                    placeholder="Bitte wählen..."
                                    item-text="text"
                                    item-value="value"
                                    dense
                                    filled
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="8" cols="12">
                                <v-select
                                    label="Haben Sie Kinder"
                                    v-model="data.childrenStatus"
                                    :items="yesNoTypes"
                                    placeholder="Bitte wählen..."
                                    item-text="text"
                                    item-value="value"
                                    dense
                                    filled
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="data.childrenStatus === 'Ja'">
                            <v-col sm="4" cols="12">
                                <v-text-field v-model="data.numberOfChildren"
                                              label="Wie viele Kinder haben Sie?"
                                              placeholder="z.B. 2"
                                              dense />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
        <StepButtons :firstStep="firstStep" :lastStep="lastStep" @previous="$emit('previous')" @next="$emit('next')"/>
    </div>
</template>
<script>
import StepButtons from "@/components/StepButtons";

export default {
    name: 'PersonalInformation',
    components: {StepButtons},
    props: {
        data: {

        },
        firstStep: {},
        lastStep: {}
    },
    data() {
        return {
            yesNoTypes: [
                {
                    text: 'Ja',
                    value: 'Ja'
                },
                {
                    text: 'Nein',
                    value: 'Nein'
                },
                {
                    text: 'Sonstiges / keine Angabe',
                    value: 'Sonstiges / keine Angabe'
                },
            ],
            familyStatusTypes: [
                {
                    text: 'Verheiratet',
                    value: 'Verheiratet'
                },
                {
                    text: 'Geschieden',
                    value: 'Geschieden'
                },
                {
                    text: 'Ledig',
                    value: 'Ledig'
                },
                {
                    text: 'Verwitwet',
                    value: 'Verwitwet'
                },
                {
                    text: 'Getrennt lebend',
                    value: 'Getrennt lebend'
                },
                {
                    text: 'Eingetragene Lebenspartnerschaft',
                    value: 'Eingetragene Lebenspartnerschaft'
                },
                {
                    text: 'Sonstiges / keine Angabe',
                    value: 'Sonstiges / keine Angabe'
                },
            ],
        }
    },
    mounted() {
    }
}
</script>
