<template>
  <div>
    <v-card class="ma-sm-1 mb-sm-6 mb-2 flatcard" color="grey lighten-5">
      <v-card-title>
        Ihr Ergebnis
      </v-card-title>
      <v-card-text>
        <v-container class="ma-0 pa-0">
          <v-row v-if="result === 'red'" no-gutters>
            <v-col cols="12" class="text-center d-block d-sm-none">
              <v-sheet elevation="2" class="pa-2">
                <img src="../assets/trafficlight_red.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
            <v-col md="9" sm="8" cols="12" class="">
              <p>
                  Zunächst, Sie können ohne ein Insolvenzverfahren von Ihren Schulden befreit werden, wenn
                  alle Gläubiger zustimmen (außergerichtliche Schuldenbereinigung). Das ist doch schon mal
                  beruhigend. Damit die Schulden nicht noch mehr werden, sollten Sie nun jedoch reagieren.
                  Wie?
              </p>
              <p>
                  Sie können mit den Gläubigern eine monatliche Rate vereinbaren, nach einer ersten Prüfung
                  Ihrer Angaben z.B. wie folgt:
              </p>
                  <table class="inline-table">
                        <tr>
                            <th>Monatliche Rate</th>
                            <td>{{garnishmentResult.monthlyRate}} Euro <sup>*</sup></td>
                        </tr>
                        <tr v-if="garnishmentResult.monthsUntilDebtFree !== null">
                            <th>Schuldenfrei in</th>
                            <td>{{garnishmentResult.monthsUntilDebtFree}} Monaten</td>
                        </tr>
                        <tr>
                            <th>Ziel</th>
                            <td>Vermeidung der Insolvenz <sup>**</sup></td>
                        </tr>
                  </table>
              <p>
                  Falls Ihnen das alles gerade zu viel ist und Sie nicht wissen, wie Sie mit den Gläubigern in
                  Kontakt treten sollen, lassen Sie sich kostenfrei und unverbindlich in einem ersten Telefonat
                  von uns beraten.
              </p>
            </v-col>
            <v-col md="3" sm="4" cols="4" class="text-center d-none d-sm-block">
              <v-sheet elevation="2" class="pa-2 ml-2">
                <img src="../assets/trafficlight_red.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row v-if="result === 'green'" no-gutters>
            <v-col cols="12" class="text-center d-block d-sm-none">
              <v-sheet elevation="2" class="pa-2">
                <img src="../assets/trafficlight_green.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
            <v-col md="9" sm="8" cols="12" class="mt-2 mt-sm-0">
              <p>
                  Glückwunsch! Sie haben keine Probleme und müssen keinen Insolvenzantrag stellen!
              </p>
              <p>
                  Bitte beachten Sie, dass das Ergebnis eine kurze Prüfung zugrunde liegt, nur einen
                  vorübergehenden Überblick darstellt und der Insolvenzrechner eine professionelle Beratung
                  nicht ersetzt.
              </p>
              <p>
                  Lassen Sie sich daher gerne von uns unverbindlich und in einem ersten
                  Telefonat kostenlos beraten.
              </p>
            </v-col>
            <v-col md="3" sm="4" cols="4" class="text-right d-none d-sm-block">
              <v-sheet elevation="2" class="pa-2">
                <img src="../assets/trafficlight_green.png" style="width: 100%;"/>
              </v-sheet>
            </v-col>
          </v-row>
          <v-container class="ma-0 mt-sm-2 pa-1 text-center" fluid>
            <v-row v-if="result === 'red'">
              <v-col cols="12">
                <PrivateRegistrationDialog :form="form" button-style="primary"/>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <PrivateRegistrationDialog :form="form" button-style="secondary"/>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="result === 'red'" class="ma-0 mt-sm-2 pa-1" fluid>
            <v-row>
              <v-col cols="12">
                  <p>
                      <small>
                          <sup>*</sup>Bitte beachten Sie, dass das Ergebnis eine kurze Prüfung zugrunde liegt, nur einen
                          vorübergehenden Überblick darstellt und der Insolvenzrechner eine professionelle Beratung
                          nicht ersetzt. Die Berechnung der genauen Höhe der monatlichen Rate findet im Rahmen
                          unseres kostenlosen und unverbindlichen Erstgesprächs statt.
                      </small>
                  </p>
                  <p>
                      <small>
                          <sup>**</sup>Sofern Ihnen ein Ratenzahlungsangebot an Ihre Gläubiger finanziell nicht möglich
                          ist und
                          Sie einen Insolvenzantrag stellen möchten, beraten wir Sie gerne zu einem möglichen
                          Privatinsolvenzverfahren - selbstverständlich kostenlos und unverbindlich.
                      </small>
                  </p>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-card-text>
    </v-card>
    <v-container class="ma-0 pa-1 text-center" fluid>
      <v-btn color="grey" text rounded @click="$emit('reset')">Gleich nochmal!</v-btn>
    </v-container>
  </div>
</template>
<script>
import PrivateRegistrationDialog from "@/components/PrivateRegistrationDialog";

export default {
  name: 'PrivateInsolvencyResultView',
  components: {PrivateRegistrationDialog},
  props: {
    form: {},
    result: {},
    garnishmentResult: {}
  }
}
</script>
<style>
.inline-table {
    margin-bottom: 20px;
}
.inline-table th {
        text-align: left;
        padding-right: 20px;
    }
</style>
