<template>
  <v-container fluid>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 1" step="1">
          Ihre finanzielle Situation
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">
          Persönliche Angaben
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3">
          Zusatzinformationen
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4">
          Ihr Ergebnis
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <PrivateFinancialSituation :data="form.privateInsolvencyData"
                              @next="step = 2"/>
        </v-stepper-content>
        <v-stepper-content step="2">
          <PersonalInformation :data="form.privateInsolvencyData"
                              @previous="step = 1" @next="step = 3"/>
        </v-stepper-content>
        <v-stepper-content step="3">
          <AdditionalInformation :data="form.privateInsolvencyData" :lastStep="true"
                              @previous="step = 2" @next="calculateResult"/>
        </v-stepper-content>

        <v-stepper-content step="4">
          <PrivateInsolvencyResultView :form="form" :result="result" :garnishmentResult="garnishmentResult"
                                @reset="resetForm"
                                />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>
<script>
import PrivateFinancialSituation from "@/components/PrivateFinancialSituation";
import PersonalInformation from "@/components/PersonalInformation";
import AdditionalInformation from "@/components/AdditionalInformation";
import PrivateInsolvencyResultView from "@/components/PrivateInsolvencyResultView";
import axios from "axios";
import {createPrivateInsolvencyDataPayload} from "@/helper";

export default {
  name: 'PrivateInsolvencyForm',
    components: {
        PrivateFinancialSituation,
        PersonalInformation,
        AdditionalInformation,
        PrivateInsolvencyResultView
    },
  props: {
    form: {},
  },
  data() {
    return {
      step: 1,
      result: null,
      garnishmentResult: null
    }
  },
  methods: {
    resetForm: function () {
      this.result = null;
      this.step = 1;
    },
    calculateResult: function () {
      let self = this;
      axios.post(`${process.env.VUE_APP_ENDPOINT}/validate/private-insolvency`,createPrivateInsolvencyDataPayload(this.form),{
        timeout: 10000
      })
          .then(function (response) {
            console.log(response);
            console.log("result", response.data.privateInsolvencyStatus);
            self.result = response.data.privateInsolvencyStatus.toLowerCase();
            self.garnishmentResult = response.data.garnishmentResult;
            self.step = 4;
          })
          .catch(function (error) {
            console.log(error);
            // TODO: show error message in toast
          });
    }
  }
}
</script>
<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
