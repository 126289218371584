// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from 'vuetify/lib/util/colors'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        themes: {
            light: {
                primary: '#f6bb2e', //colors.orange.lighten1,
                //secondary: colors.purple.lighten4, //colors.grey, // #FFCDD2
                secondary: colors.brown.lighten3, //colors.grey, // #FFCDD2
                accent: colors.orange.base, // #3F51B5
            },
        },
    },
}
export default new Vuetify(opts)
