<template>
  <div>
    <v-card class="ma-sm-1 mb-sm-6 mb-2 flatcard" color="grey lighten-5">
      <v-card-title>
        Finanzsituation heute
      </v-card-title>
      <v-card-text>
        <p class="d-inline d-sm-none">
          Bitte beantworten Sie folgende Fragen zur finanziellen Situation zum Stichtag heute:
        </p>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row>
              <v-col sm="10" cols="9" class="ma-0">
                <span class="text-subtitle-1">Bestehen bereits zum heutigen Tag überfällige Verbindlichkeiten?</span>
              </v-col>
              <v-col sm="2" cols="3" class="ma-0 pa-0">
                <v-switch v-model="insolvencyData.currentDebtOverdue" label="Ja" inset></v-switch>
              </v-col>
            </v-row>
            <v-row v-if="insolvencyData.currentDebtOverdue">
              <v-col sm="8" cols="12">
                <v-text-field v-model="insolvencyData.currentDebt"
                              required="overdue"
                              label="Höhe der überfälligen Verbindlichkeiten" dense v-currency/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0 mt-5">
                <span class="text-subtitle-1">Bitte geben Sie nachfolgend die Daten zum Stichtag heute an:</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4" cols="12">
                <v-text-field v-model="insolvencyData.balance" label="Kontostand" placeholder="123,45"
                              dense v-currency/>
              </v-col>
              <v-col sm="4" cols="12">
                <v-text-field v-model="insolvencyData.creditLimit" label="Bestehende Kreditlinie / verfügbarer Kreditbetrag" dense
                              v-currency/>
              </v-col>
              <v-col sm="4" cols="12">
                <v-text-field v-model="insolvencyData.cash" label="Barmittel & Liquiditätsreserven" dense v-currency/>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <StepButtons :firstStep="firstStep" :lastStep="lastStep" @previous="$emit('previous')" @next="$emit('next')"/>
  </div>
</template>
<script>
import StepButtons from "@/components/StepButtons";

export default {
  name: 'FinancialSituation',
  components: {StepButtons},
  props: {
    insolvencyData: {},
    firstStep: {},
    lastStep: {}
  },
  data() {
    return {
    }
  },
  mounted() {
  }
}
</script>
