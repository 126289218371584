<template>
  <div>
    <v-card class="ma-sm-1 mb-sm-6 mb-2 flatcard" color="grey lighten-5">
      <v-card-title>
        Überschuldung
      </v-card-title>
      <v-card-text>
        <p>
          Um eine Überschuldung feststellen zu können, sind eine Überschuldungsbilanz und eine Fortführungsprognose zu
          erstellen. Übersteigen die Verbindlichkeiten das vorhandene Vermögen und ist die Fortführungsprognose negativ
          liegt Überschuldung vor.
        </p>
        <p>
          Geben Sie bitte im Folgenden die Werte Ihrer Aktiva und Passiva an. Bei der Wertermittlung der Aktiva und
          Passiva sind nicht die Werte der Handels- oder Steuerbilanz (Buchwerte) heranzuziehen. Sie müssen jeweils die
          Liquidationswerte ansetzen.
        </p>
      </v-card-text>
    </v-card>
    <StepButtons :firstStep="firstStep" :lastStep="lastStep" @previous="$emit('previous')" @next="$emit('next')"/>
  </div>
</template>
<script>
import StepButtons from "@/components/StepButtons";

export default {
  name: 'IndebtnessIntro',
  components: {StepButtons},
  props: {
    firstStep: {},
    lastStep: {},
    data: {},
  }
}
</script>
<style>
@media screen and (max-width: 675.98px) {
  .v-stepper__content {
    padding: 0 !important;
  }
}
</style>
