<template>
  <v-dialog
      v-model="registrationForm"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="buttonStyle" class="mb-1" elevation="2" large x-sm-large v-bind="attrs" v-on="on">
        Beratung anfordern
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="registrationForm = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Beratung anfordern</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!--          <v-btn dark text @click="registrationForm = false">-->
          <!--            Jetzt anmelden-->
          <!--          </v-btn>-->
        </v-toolbar-items>
      </v-toolbar>
      <v-form ref="form" lazy-validation @submit.prevent="doRegistration" v-if="!success">
        <v-overlay absolute :value="showOverlay">
          <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-overlay>
        <v-card-text>
          <v-container>
            <v-card-title>Ihre Stammdaten</v-card-title>
            <v-row>
              <v-col xl="10" md="8" lg="9" sm="12" xs="12" cols="12" class="py-1">
                <v-text-field filled v-model="form.baseData.company" label="Firma" :counter="30" maxlength="30" dense
                              :rules="companyRules"/>
              </v-col>
              <v-col xl="2" md="4" lg="3" sm="12" xs="12" cols="12" class="py-1">
                <v-text-field filled v-model="form.baseData.companyType" label="Unternehmensform" :counter="20"
                              maxlength="20" dense
                              :rules="companyTypeRules"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-1">
                <v-text-field filled v-model="form.baseData.ceo" label="Ansprechpartner / Geschäftsführer/in" dense :counter="50"
                              maxlength="50"
                              :rules="ceoRules"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-1">
                <v-text-field filled v-model="form.baseData.street" label="Straße / Hausnr." dense :counter="50"
                              maxlength="50"
                              :rules="streetRules"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3" sm="4" xs="12" cols="12" class="py-1">
                <v-text-field filled v-model="form.baseData.zipcode" label="PLZ" dense :counter="5" maxlength="5"
                              :rules="zipRules"/>
              </v-col>
              <v-col md="9" sm="8" xs="12" cols="12" class="py-1">
                <v-text-field filled v-model="form.baseData.city" label="Stadt" dense :counter="30" maxlength="30"
                              :rules="cityRules"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-1">
                <v-text-field filled v-model="form.baseData.website" label="Webpage" dense :counter="40"
                              maxlength="40"/>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-title>Ihre Kontaktdaten</v-card-title>
            <v-row>
              <v-col xl="3" lg="6" md="6" sm="12" xs="12" cols="12" class="py-1">
                <v-text-field filled v-model="form.baseData.phone" label="Telefon" dense :counter="15" maxlength="15"
                              :rules="phoneRules"/>
              </v-col>
              <v-col xl="3" lg="6" md="6" sm="12" xs="12" cols="12" class="py-1">
                <v-text-field filled v-model="form.baseData.email" label="E-Mail" dense :counter="80" maxlength="80"
                              :rules="emailRules"/>
              </v-col>
              <v-col xl="3" lg="6" md="6" sm="12" xs="12" cols="12" class="py-1">
                <v-select
                    label="Wann können wir Sie am Besten erreichen?"
                    v-model="form.baseData.preferredContactTime"
                    :items="contactTimes"
                    placeholder="Bitte wählen..."
                    item-text="text"
                    item-value="value"
                    dense
                    filled
                ></v-select>
              </v-col>
              <v-col xl="3" lg="6" md="6" sm="12" xs="12" cols="12" class="py-1">
                <v-select
                    label="Wie möchten Sie kontaktiert werden?"
                    v-model="form.baseData.preferredContactType"
                    :items="contactTypes"
                    placeholder="Bitte wählen..."
                    item-text="text"
                    item-value="value"
                    dense
                    filled
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" class="ma-0 py-0">
                <v-checkbox v-model="form.baseData.terms" label="Ich akzeptiere die AGB und die Datenschutzerklärung." required />
              </v-col>
              <v-col cols="12" sm="6" class="ma-0 py-0">
                <v-checkbox v-model="form.baseData.newsletter" label="Ich möchte den Brandt Legal Newsletter erhalten." />
              </v-col>
            </v-row>
            <v-row class="d-none d-sm-block">
              <v-col cols="12" class="text-right">
                <v-btn color="secondary" class="mr-5" text @click="registrationForm = false" large>
                  Abbrechen
                </v-btn>
                <v-btn color="primary" type="submit" :disabled="!isValid" large>
                  Jetzt anmelden
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="d-sm-none d-block">
              <v-col cols="12" class="text-center">
                <v-btn color="primary" type="submit" :disabled="!isValid" x-large>
                  Jetzt anmelden
                </v-btn>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn color="secondary" class="mr-5" text @click="registrationForm = false" large>
                  Abbrechen
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
      <v-container class="pa-10 d-flex justify-center" v-else>
          <v-card class="pa-10 text-center">
            <v-icon size="90" class="mb-5" color="light-green">mdi-check-circle-outline</v-icon>
            <h1 class="mb-5">
              Vielen Dank
            </h1>
            <p class="mb-5 px-10">
              Bitte schließen Sie die Registrierung ab,
              in dem Sie auf den Link klicken, den wir Ihnen gerade per Email gesendet haben.
            </p>
            <v-btn color="primary" @click="registrationForm=false">OK</v-btn>
          </v-card>
      </v-container>
    </v-card>
    <v-snackbar v-model="showError" timeout="3000">
      {{ error }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="showError = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>
<script>
import axios from "axios";
import {createIndebtnessDataPayload,createInsolvencyDataPayload} from "@/helper";

export default {
  name: 'RegistrationDialog',
  components: {},
  props: {
    form: {},
    firstStep: {},
    lastStep: {},
    buttonStyle: {}
  },
  data: () => ({
    success: false,
    showError: false,
    showOverlay: false,
    error: '',
    registrationForm: false,
    valid: true,
    contactTimes: [
      {
        text: 'Keine Präferenz',
        value: 'None'
      },
      {
        text: 'Vormittags',
        value: 'Morning'
      },
      {
        text: 'Nachmittags',
        value: 'Afternoon'
      },
    ],
    contactTypes: [
      {
        text: 'Keine Präferenz',
        value: 'None'
      },
      {
        text: 'Per E-Mail',
        value: 'Email'
      },
      {
        text: 'Per Telefon',
        value: 'Phone'
      },
    ],
    companyRules: [
      v => !!v || 'Firma ist ein Pflichtfeld',
    ],
    companyTypeRules: [
      v => !!v || 'Unternehmensform ist ein Pflichtfeld',
    ],
    ceoRules: [
      v => !!v || 'Geschäftsführer ist ein Pflichtfeld',
    ],
    streetRules: [
      v => !!v || 'Straße / Hausnr. ist ein Pflichtfeld',
    ],
    cityRules: [
      v => !!v || 'Stadt ist ein Pflichtfeld',
    ],
    emailRules: [
      v => !!v || 'E-Mail ist ein Pflichtfeld',
      v => /.+@.+\..+/.test(v) || 'Bitte geben Sie eine gültige E-Mail-Adresse an',
    ],
    phoneRules: [
      v => !!v || 'Telefon ist ein Pflichtfeld',
      v => /^[0-9 +\-()/]{4,15}$/.test(v) || 'Bitte geben Sie eine gültige Telefonnummer an',
    ],
    zipRules: [
      v => !!v || 'PLZ ist ein Pflichtfeld',
      v => /[0-9]{4,5}/.test(v) || 'Bitte geben Sie eine gültige Postleitzahl an',
    ],
  }),
  computed: {
    isValid: function () {
      return this.form.baseData.company !== ''
          && this.form.baseData.companyType !== ''
          && this.form.baseData.ceo !== ''
          && this.form.baseData.street !== ''
          && this.form.baseData.city !== ''
          && this.form.baseData.zipcode !== ''
          && this.form.baseData.email !== ''
          && this.form.baseData.phone !== ''
          && this.form.baseData.terms
          ;
    }
  },
  methods: {
    doRegistration: function () {
      console.log("Can do!");
      this.showOverlay = true;
      let self = this;
      axios.post(
          `${process.env.VUE_APP_ENDPOINT}/account/register`,
          {
            baseData: {
              company: this.form.baseData.company,
              companyType: this.form.baseData.companyType,
              ceo: this.form.baseData.ceo,
              street: this.form.baseData.street,
              zipcode: this.form.baseData.zipcode,
              city: this.form.baseData.city,
              phone: this.form.baseData.phone,
              email: this.form.baseData.email,
              website: this.form.baseData.website,
              newsletter: this.form.baseData.newsletter,
              preferredContactType: this.form.baseData.preferredContactType,
              preferredContactTime: this.form.baseData.preferredContactTime,
            },
            financialSituation: {
              ...createInsolvencyDataPayload(this.form),
              ...createIndebtnessDataPayload(this.form)
            },
          },
          {
            timeout: 10000,
          })
          .then(function (response) {
            console.log(response);
            self.success = true;
          })
          .catch(function (error) {
            console.log(error);
            self.error = error;
            self.showError = true;
          })
          .finally(() => this.showOverlay = false)
      ;
    }
  }
}
</script>
