<template>
    <div>
        <v-card class="ma-sm-1 mb-sm-6 mb-2 flatcard" color="grey lighten-5">
            <v-card-title>
                Ihre Einnahmen
            </v-card-title>
            <v-card-text>
                <p class="d-inline d-sm-none">
                    Bitte beantworten Sie folgende Fragen zu ihrer Einnahmesituation:
                </p>
                <v-form ref="form" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col sm="8" cols="12">
                                <v-select
                                    label="In was für einem Beschäftigungsverhältnis befinden Sie sich?"
                                    v-model="data.employmentType"
                                    :items="employmentTypes"
                                    placeholder="Bitte wählen..."
                                    item-text="text"
                                    item-value="value"
                                    dense
                                    filled
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="8" cols="12">
                                <v-select
                                    label="Waren Sie jemals als Selbstständige/r tätig?"
                                    v-model="data.selfEmployment"
                                    :items="yesNoTypes"
                                    placeholder="Bitte wählen..."
                                    item-text="text"
                                    item-value="value"
                                    dense
                                    filled
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" cols="12">
                                <v-text-field v-model="data.netIncome"
                                              label="Wie hoch ist Ihr Nettoeinkommen (in Euro)?"
                                              placeholder="123,45"
                                              dense v-currency hint="Geben Sie ggf. einen Schätzwert ein."/>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col sm="8" cols="12">
                                <v-select
                                    label="Wie viele offene Forderungen haben Sie?"
                                    v-model="data.openClaims"
                                    :items="openClaimsTypes"
                                    placeholder="Bitte wählen..."
                                    item-text="text"
                                    item-value="value"
                                    dense
                                    filled
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="8" cols="12">
                                <v-select
                                    label="Haben Sie bereits einen Brief von einem Gerichtsvollzieher erhalten (z.B. Pfändung)?"
                                    v-model="data.bailiffBond"
                                    :items="bailiffBondTypes"
                                    placeholder="Bitte wählen..."
                                    item-text="text"
                                    item-value="value"
                                    dense
                                    filled
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" cols="12">
                                <v-text-field v-model="data.debt" label="Wie hoch sind Ihre Gesamtschulden (in Euro)?"
                                              placeholder="123,45"
                                              dense v-currency hint="Geben Sie ggf. einen Schätzwert ein."/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
        <StepButtons :firstStep="firstStep" :lastStep="lastStep" @previous="$emit('previous')" @next="$emit('next')"/>
    </div>
</template>
<script>
import StepButtons from "@/components/StepButtons";

export default {
    name: 'PrivateFinancialSituation',
    components: {StepButtons},
    props: {
        data: {},
        firstStep: {},
        lastStep: {}
    },
    data() {
        return {
            yesNoTypes: [
                {
                    text: 'Ja',
                    value: 'Ja'
                },
                {
                    text: 'Nein',
                    value: 'Nein'
                },
                {
                    text: 'Sonstiges / keine Angabe',
                    value: 'Sonstiges / keine Angabe'
                },
            ],
            openClaimsTypes: [
                {
                    text: 'Keine',
                    value: 'Keine'
                },
                {
                    text: '1 - 9',
                    value: '1 - 9'
                },
                {
                    text: '10 - 25',
                    value: '10 - 25'
                },
                {
                    text: '> 25',
                    value: '> 25'
                },
                {
                    text: 'Unsicher / keine Angabe',
                    value: 'Unsicher / keine Angabe'
                },
            ],
            bailiffBondTypes: [
                {
                    text: 'Ja',
                    value: 'Ja'
                },
                {
                    text: 'Nein',
                    value: 'Nein'
                },
                {
                    text: 'Unsicher / keine Angabe',
                    value: 'Unsicher / keine Angabe'
                },
                {
                    text: 'Ich öffne meine Briefe nicht',
                    value: 'Ich öffne meine Briefe nicht'
                },
            ],
            employmentTypes: [
                {
                    text: 'Bitte wählen ...',
                    value: ''
                },
                {
                    text: 'Angestellte/r, Beamtin/er',
                    value: 'Angestellte/r, Beamtin/er'
                },
                {
                    text: 'Rentner/in',
                    value: 'Rentner/in'
                },
                {
                    text: 'Arbeitslos',
                    value: 'Arbeitslos'
                },
                {
                    text: 'Selbständige/r, Freiberufler/in',
                    value: 'Selbständige/r, Freiberufler/in'
                },
                {
                    text: 'Dienstverhältnis als Geschäftsführer/in einer jur. Person (z.B. GmbH, UG)',
                    value: 'Dienstverhältnis als Geschäftsführer/in einer jur. Person (z.B. GmbH, UG)'
                },
                {
                    text: 'Sonstiges / keine Angabe',
                    value: 'Sonstiges / keine Angabe'
                },
            ],
        }
    },
    mounted() {
    }
}
</script>
